import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import Edit from './Edit'
import { Button, Heading, Text } from '@chakra-ui/core'

export default function Users(props) {
  // const [users, setUsers] = useState(null)
  const users = props.data
  const tableCollumns = [
    '_id',
    'email',
    'firstName',
    'lastName',
    'role',
    'admin',
    'status'
  ]
  const [openModal, setOpenModal] = useState(false)
  props.setStorageState('pageClass', 'users')

  const options = {
    filter: true,
    filterType: 'checkbox',
    count: users ? users.length : 0,
    downloadOptions: { filename: 'report.csv', separator: ';' },

    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      const selectedIds = selectedRows.data.map(sel => users[sel.dataIndex]._id)
      return (
        <div className="table-actions">
          <Button
            onClick={() =>
              props.setRemoteCall('delUser', selectedIds, ` Deleted users`)
            }
          >
            Delete Selected
          </Button>
        </div>
      )
    },
    onRowClick: (rowData, rowMeta) => {
      setOpenModal(users[rowMeta.dataIndex])
    }
  }

  return (
    <div className={'wrapper'}>
      <Heading>
        Registered users{' '}
        <Button onClick={() => setOpenModal({})}>Add new User </Button>
      </Heading>
      <Text fontSize={'.8em'}>
        To delete users select them from the list and choose the button on the
        top right of the table
        <br />
        <strong>
          Only admin set to true user will have access to view and modify the
          user panel
        </strong>
        <br />
        <br />
        <strong>
          Remember to set the Role (Partner) for the user, without a role the
          user wont be able to preview the game feed
        </strong>
      </Text>
      {users && (
        <div className={'tableWrapper'}>
          <MUIDataTable
            data={users}
            columns={tableCollumns.map(key => {
              return {
                name: key,
                label: key,
                selector: key,
                options: {
                  filter: true,
                  sort: true,
                  display: key !== '_id' && key !== '__v'
                }
              }
            })}
            options={options}
          />
        </div>
      )}
      {openModal && (
        <Edit
          data={openModal}
          form={tableCollumns}
          setRemoteCall={props.setRemoteCall}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  )
}
