//axios setup
import axios from 'axios'

export function formatDate(date) {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var seconds = date.getSeconds()
  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds
  var strTime = hours + ':' + minutes + ':' + seconds
  return (
    date.getMonth() +
    1 +
    '/' +
    date.getDate() +
    '/' +
    date.getFullYear() +
    '  ' +
    strTime
  )
}

export const presistData = (type, data) => {
  try {
    if (typeof localStorage !== 'undefined')
      localStorage[type] = JSON.stringify({ ...data, status: '' })
  } catch (error) {
    console.log(error)
  }
  return data
}

export const getPresistData = (type, data) => {
  try {
    if (typeof localStorage !== 'undefined' && localStorage[type]) {
      return JSON.parse(localStorage[type])[data]
    }
  } catch (error) {
    console.log(error)
  }
  return null
}

export const configAxios = () => {
  axios.defaults.baseURL = `${
    process.env.REACT_APP_expressDomain || 'https://api.oddsplacardpt.com'
  }/api/`

  axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded'
  axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'
  axios.defaults.withCredentials = false // for session keeping
  try {
    if (typeof localStorage === 'undefined' && !localStorage.getItem('app')) {
      throw new Error('no user data')
    }

    const userData = JSON.parse(localStorage.getItem('app') || null)
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + userData.loggedIn.token
  } catch (error) {
    axios.defaults.headers.common['Authorization'] = ''
  }
}
