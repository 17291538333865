import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Modal } from 'react-responsive-modal'
import {
  Box,
  Text,
  Button,
  InputLeftAddon,
  InputGroup,
  Input,
  Heading,
  Select
} from '@chakra-ui/core'

export default function Edit(props) {
  const [details, setDetails] = useState(props.data || {})

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }

  return (
    <Modal
      open={true}
      onClose={props.setOpenModal}
      classNames={{
        overlay: 'editOverlay',
        modal: 'editModal'
      }}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <Heading marginBottom={'3em'}>
        Edit {details ? details.firstName : ''} details
      </Heading>

      {props.form.map(k => {
        if (k === '_id') return null

        if (k === 'admin') {
          return (
            <InputGroup margin={'1em 0'} size="sm">
              <InputLeftAddon
                minWidth={'120px'}
                textAlign={'center'}
                padding={'1.5em .5em'}
                children={k}
                fontWeight={'bold'}
              />
              <Select
                height="3em"
                onChange={event => handleFormFieldChange(k, event)}
                placeholder="Select option"
                value={details[k]}
              >
                <option value={'false'}>User </option>
                <option value={'true'}> Administrator </option>
              </Select>
            </InputGroup>
          )
        }
        if (k === 'status') {
          return (
            <InputGroup margin={'1em 0'} size="sm">
              <InputLeftAddon
                minWidth={'120px'}
                textAlign={'center'}
                padding={'1.5em .5em'}
                children={k}
                fontWeight={'bold'}
              />
              <Select
                height="3em"
                onChange={event => handleFormFieldChange(k, event)}
                placeholder="Select option"
                value={details[k]}
              >
                <option value={0}>Disabled </option>
                <option value={1}> Active </option>
              </Select>
            </InputGroup>
          )
        }
        if (k === 'role') {
          return (
            <>
              <InputGroup margin={'1em 0'} size="sm">
                <InputLeftAddon
                  minWidth={'120px'}
                  textAlign={'center'}
                  padding={'1.5em .5em'}
                  children={k}
                  fontWeight={'bold'}
                />
                <Input
                  padding={'1.5em .5em'}
                  onChange={event => handleFormFieldChange(k, event)}
                  placeholder={k}
                  value={details[k]}
                />
                <br />
              </InputGroup>
              <Text fontSize={'.8em'} fontWeight={'bold'}>
                In the role you can use any name. Users with role "global" can
                create other users
              </Text>
            </>
          )
        }
        return (
          <>
            <InputGroup margin={'1em 0'} size="sm">
              <InputLeftAddon
                minWidth={'120px'}
                textAlign={'center'}
                padding={'1.5em .5em'}
                children={k}
                fontWeight={'bold'}
              />
              <Input
                padding={'1.5em .5em'}
                onChange={event => handleFormFieldChange(k, event)}
                placeholder={k}
                value={details[k]}
              />
            </InputGroup>
          </>
        )
      })}
      <Button
        marginTop={'3em'}
        alignSelf={'right'}
        onClick={() =>
          props.setRemoteCall('setUser', details, ` updating user`)
        }
      >
        Save
      </Button>
    </Modal>
  )
}
