import React, { useEffect, useState, useMemo } from 'react'
import MUIDataTable from 'mui-datatables'
import { Button, Icon, Text, Heading } from '@chakra-ui/core'
import { formatDate } from '../util'

export default function Dashboard(props) {
  const games = props.data
  const tableCollumns = [
    '_id',
    'highlight',
    'priority',
    'sport',
    'startDate',
    'lastUpdate',
    'leagues',
    'country',
    'localizedNames',
    'homeTeam',
    'homeOdd',
    'drawOdd',
    'awayOdd',
    'awayTeam',
    'markets',
    'status',
    'url',
    'eventID',
    'parentNodeId',
    'eventNodeTypeId'
  ]

  props.setStorageState('pageClass', 'users')

  const setPrioritySelection = async (percent, items) => {
    props.setRemoteCall(
      'setGamePriority',
      {
        items,
        set: 'priority',
        value: percent
      },
      ' change game priority'
    )
  }

  const setHighlightSelection = async (val, items) => {
    props.setRemoteCall(
      'setGameHighlight',
      {
        items,
        set: 'highlight',
        value: val
      },
      ' change game Highlight'
    )
  }

  const options = {
    filterType: 'dropdown',
    setRowProps: row => {
      const value = row[1]
      let color = ''
      if (value > 0 && value < 40) {
        color = '#ffeded'
      } else if (value >= 40 && value < 80) {
        color = '#fff7e9'
      } else if (value >= 80 && value <= 100) {
        color = '#e6ffee'
      }

      return {
        style: { background: `${color}` }
      }
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      const selectedIds = selectedRows.data.map(
        sel => games[sel.dataIndex].eventID
      )
      return (
        <div className="table-actions">
          <div>
            <strong>Priority</strong>{' '}
            {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((percent, i) => {
              let color = ''
              let background = ''
              if (percent > 0 && percent < 40) {
                background = '#ffeded'
              } else if (percent >= 40 && percent < 80) {
                background = '#fff7e9'
              } else if (percent >= 80 && percent <= 100) {
                background = '#e6ffee'
              } else {
                background = '#fff3f3'
              }
              return (
                <Button
                  key={i}
                  background={background}
                  marginRight={'.3em'}
                  onClick={() => setPrioritySelection(percent, selectedIds)}
                >
                  {percent}
                </Button>
              )
            })}
            {'  '}
            <strong>Highlight</strong>{' '}
            <Button
              background={'#fff3f3'}
              marginRight={'.3em'}
              onClick={() => setHighlightSelection(false, selectedIds)}
            >
              X
            </Button>
            <Button
              background={'#e6ffee'}
              marginRight={'.3em'}
              onClick={() => setHighlightSelection(true, selectedIds)}
            >
              ✓
            </Button>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={'wrapper'}>
      <Heading as="h1" size="2xl" marginBottom={'.2em'}>
        <Icon name="calendar" /> Available games
      </Heading>
      <Text fontSize={'.8em'}>
        The listed events will display in the adds according to the priority and
        the filtering. <br />
        To set priorities please choose the games first,{' '}
        <strong>events without priority we consider having priority 50</strong>
      </Text>

      {games && games.length > 0 && (
        <div className={'tableWrapper'}>
          <MUIDataTable
            data={games}
            // title={'Available games'}
            expandableRows={true}
            expandableRowsOnClick={true}
            columns={tableCollumns.map(key => {
              if (key === 'highlight') {
                return {
                  name: key,
                  label: key,
                  selector: key,
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return value && '✓'
                    }
                  }
                }
              }
              if (key === 'lastUpdate') {
                return {
                  name: key,
                  label: key,
                  selector: key,
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return value && formatDate(new Date(value))
                    }
                  }
                }
              }
              if (key === 'startDate') {
                return {
                  name: key,
                  label: key,
                  selector: key,
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return value && formatDate(new Date(value))
                    }
                  }
                }
              }
              if (key === 'markets') {
                return {
                  name: key,
                  label: key,
                  selector: key,
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return value && JSON.stringify(value)
                    }
                  }
                }
              }
              return {
                name: key,
                label: key,
                selector: key,
                options: {
                  filter: true,
                  sort: true,
                  display: key !== '_id' && key !== '__v'
                }
              }
            })}
            options={options}
          />
        </div>
      )}
    </div>
  )
}
