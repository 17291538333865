import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { configAxios } from './util'
import 'react-toastify/dist/ReactToastify.css'
import 'react-responsive-modal/styles.css'

import App from './app/App'
import * as serviceWorker from './serviceWorker'
configAxios()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
