import React, { Component } from 'react'
import './Login.css'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Button, Flex, Input } from '@chakra-ui/core'
class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: ''
    }
    props.setStorageState('pageClass', 'login')
  }

  resetPassword = async e => {
    if (!this.state.email || this.state.email === '') {
      toast('Por favor introduza o email')
    }

    const response = await axios.post(`/auth/forgot`, {
      email: this.state.email
    })
    toast('Verifique a sua caixa de correio')
  }

  loginUser = async e => {
    e.preventDefault()

    const { email, password } = this.state
    if (this.state.email === '' || this.state.password === '') {
      return toast('Por favor introduza o email e password')
    }

    try {
      const response = await axios.post(`/auth`, {
        email,
        password
      })
      if (response.data && response.data.token) {
        this.props.setStorageState('loggedIn', response.data)
        this.props.history.push('/dashboard')
      }
    } catch (e) {
      console.log(e)
      this.props.setStorageState('loggedIn', null)
      toast('Verifique os seus dados')
    }
  }

  handleFormFieldChange = (key, { target: { value } }) => {
    this.setState({ [key]: value })
  }

  render() {
    return (
      <Flex
        align="center"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height="100vh"
      >
        <img src={'sas-logo.png'} />
        <Box
          maxW="md"
          bg="white"
          width={'40%'}
          padding={'2em'}
          shadow="md"
          marginTop={'1em'}
          borderWidth="1px"
          rounded="lg"
          overflow="hidden"
        >
          <div className={'login-container'}>
            <div>
              <form
                className="login-form"
                onSubmit={this.loginUser}
                data-login-form
              >
                <Input
                  data-login-email
                  onChange={event => this.handleFormFieldChange('email', event)}
                  placeholder="Email"
                />
                <Input
                  type="password"
                  data-login-password
                  onChange={event =>
                    this.handleFormFieldChange('password', event)
                  }
                  placeholder="Password"
                />

                <Button
                  onClick={e => this.loginUser(e)}
                  variantColor="teal"
                  value="Submit!"
                  width={'100%'}
                >
                  Submit!
                </Button>
              </form>
              <a href={'#'} onClick={() => this.resetPassword()}>
                Reset Password
              </a>
            </div>
          </div>
        </Box>
      </Flex>
    )
  }
}

export { Login }
export default withRouter(Login)
