import React from 'react'
import Header from '../header'

export default props => {
  return (
    <div className={props.className}>
      {props.loggedIn && props.className !== 'login' && <Header {...props} />}
      <div>{props.children}</div>
    </div>
  )
}
