import React, { Component } from 'react'
import './Login.css'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { Grid, Box, Button } from '@chakra-ui/core'
class Register extends Component {
  constructor(props) {
    super(props)

    this.expressDomain =
      process.env.REACT_APP_expressDomain || 'https://oddsplacardpt.com/'

    this.state = {
      email: '',
      password: '',
    }
  }

  createUser = async (e) => {
    e.preventDefault()

    const { email, password } = this.state
    try {
      await axios.post(`${this.expressDomain}/auth/register`, {
        email,
        password,
      })

      this.setState({
        email: '',
        password: '',
      })
    } catch (e) {}
  }

  handleFormFieldChange = (key, { target: { value } }) => {
    this.setState({ [key]: value })
  }

  render() {
    console.log(this.state)
    return (
      <div>
        <h1>Register</h1>
        <div className={'login-container'}>
          <div>
            <h2>Register</h2>
            <form className="registration-form" data-register-form>
              First Name:
              <input
                type="text"
                data-register-first-name
                onChange={(event) =>
                  this.handleFormFieldChange('firstName', event)
                }
              />
              Last Name:
              <input
                type="text"
                data-register-last-name
                onChange={(event) =>
                  this.handleFormFieldChange('lastName', event)
                }
              />
              Email:
              <input
                type="email"
                data-register-username
                onChange={(event) => this.handleFormFieldChange('email', event)}
              />
              Password:
              <input
                type="password"
                data-register-password
                onChange={(event) =>
                  this.handleFormFieldChange('password', event)
                }
              />
              <Button onClick={this.createUser} value="Submit!">
                Submit!
              </Button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export { Register }
export default withRouter(Register)
