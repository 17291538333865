import React, { Component } from 'react'
import './Login.css'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Button, Flex, Input } from '@chakra-ui/core'
class Reset extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: ''
    }
    props.setStorageState('pageClass', 'login')
    console.log(props.match.params.token)
  }

  resetPassword = async () => {
    const token = this.props.match.params.token
    try {
      const response = await axios.post(`/auth/reset/${token}`, {
        password: this.state.password
      })
      console.log(response)
      if (response.data && response.data.msg) {
        this.props.history.push('/')
      } else {
        toast(`O token é invalido, verifique o link no seu email novamente`)
      }
    } catch (e) {
      console.log(e)
      toast(`O token é invalido, verifique o link no seu email novamente`)
      this.props.setStorageState('loggedIn', null)
    }
  }

  handleFormFieldChange = (key, { target: { value } }) => {
    this.setState({ [key]: value })
  }

  render() {
    return (
      <Flex
        align="center"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height="100vh"
      >
        <Box
          maxW="md"
          bg="white"
          width={'40%'}
          padding={'2em'}
          shadow="md"
          borderWidth="1px"
          rounded="lg"
          overflow="hidden"
        >
          <div className={'login-container'}>
            <div>
              <h1>Reset Password</h1>
              <form
                className="login-form"
                onSubmit={this.loginUser}
                data-login-form
              >
                <Input
                  type="password"
                  data-login-password
                  onChange={event =>
                    this.handleFormFieldChange('password', event)
                  }
                  placeholder="New Password"
                />

                <Button
                  onClick={e => this.resetPassword(e)}
                  variantColor="teal"
                  value="Submit!"
                  width={'100%'}
                >
                  Set new password!
                </Button>
              </form>
            </div>
          </div>
        </Box>
      </Flex>
    )
  }
}

export { Reset }
export default withRouter(Reset)
