import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import {
  Button,
  InputRightAddon,
  InputLeftAddon,
  InputGroup,
  Textarea,
  Input,
  Grid,
  Box,
  Icon,
  Text,
  Heading,
  Select
} from '@chakra-ui/core'
import Frame from 'react-frame-component'

/**
 *
 * @param {*} props
 * Preview banners component
 * should have a input html open field and a display on the side
 * the banner should have a feed paramter expose beside the click tag to request for specific games
 * eg: league, club, country, date
 * endpoint: /api/odds/:countries|all/:league|all/:club|all/:dateStart|all/:dateEnd|all'
 */
export default function Preview(props) {
  const games = props.data
  const [iframeContent, setIframeContent] = useState('')
  const [bannerData, setBannerData] = useState({})
  const [feedData, setFeedData] = useState({
    type: '',
    country: '',
    role: props.user.role,
    leagues: '',
    club: '',
    dateStart: '',
    dateEnd: '',
    totalItems: '',
    sort: '',
    search: new Date()
  })
  props.setStorageState('pageClass', 'preview')

  const feedUrl = `https://api.oddsplacardpt.com/api/odds/events/${
    feedData.role
  }/${encodeURIComponent(feedData.type) || 'all'}/${encodeURIComponent(
    feedData.country
  ) || 'all'}/${encodeURIComponent(feedData.leagues.split(' || ')[0]) ||
    'all'}/${encodeURIComponent(feedData.club) || 'all'}/${encodeURIComponent(
    feedData.dateStart
  ) || 'all'}/${encodeURIComponent(feedData.dateEnd) ||
    'all'}/${encodeURIComponent(feedData.totalItems) ||
    'all'}/${encodeURIComponent(feedData.sort) || 'all'}`

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(feedUrl)
      setBannerData(result.data)
    }
    fetchData()
  }, [`${feedUrl}?${feedData.search}`])

  return (
    <div className={'wrapper'}>
      <Heading as="h1" size="2xl" marginBottom={'.2em'}>
        <Icon name="check-circle" /> Preview Feed
      </Heading>
      <Text fontSize={'.8em'}></Text>
      <br />
      <br />
      <Heading as="h3" color={'#000'} size="lg" marginBottom={'1em'}>
        Url Feed generator
      </Heading>
      <Grid templateColumns="repeat(1, 1fr)" marginBottom={5}>
        <InputGroup size="sm">
          <InputLeftAddon children="Feed URL" />
          <Input
            rounded="0"
            placeholder="/api/odds/events/:eventType/:countries|all/:league|all/:club|all/:dateStart|all/:dateEnd|all/:totalItems|all/:sort|all"
            value={feedUrl}
          />
          <InputRightAddon
            cursor={'pointer'}
            onClick={() => setFeedData({ ...feedData, search: new Date() })}
            children="Fetch Again"
          />
        </InputGroup>
      </Grid>
      <Grid templateColumns="repeat(9, 1fr)" gap={6}>
        {props.user && props.user.role === 'global' && (
          <Box w="100%" h="10">
            <Select
              placeholder="Role"
              onChange={e => setFeedData({ ...feedData, role: e.target.value })}
              value={feedData.role}
            >
              {props.users
                .reduce((acc, el) => {
                  if (!acc.includes(el.role)) {
                    acc.push(el.role)
                  }
                  return acc
                }, [])
                .map(ele => (
                  <option value={ele}>{ele}</option>
                )) //summing element
              }
            </Select>
          </Box>
        )}
        <Box w="100%" h="10">
          <Select
            placeholder="Event Type"
            onChange={e => setFeedData({ ...feedData, type: e.target.value })}
            value={feedData.type}
          >
            <option value={'all'}>{'All'}</option>
            {games
              .reduce((acc, el) => {
                if (!acc.includes(el.sport)) {
                  acc.push(el.sport)
                }
                return acc
              }, [])
              .map(ele => (
                <option value={ele}>{ele}</option>
              )) //summing element
            }
          </Select>
        </Box>
        <Box w="100%" h="10">
          <Select
            placeholder="Country"
            onChange={e =>
              setFeedData({ ...feedData, country: e.target.value })
            }
            value={feedData.country}
          >
            <option value={'all'}>{'All'}</option>
            {games
              .reduce((acc, el) => {
                if (!acc.includes(el.country)) {
                  acc.push(el.country)
                }
                return acc
              }, [])
              .map(ele => (
                <option value={ele}>{ele}</option>
              )) //summing element
            }
          </Select>
        </Box>
        <Box w="100%" h="10">
          <Select
            placeholder="League"
            onChange={e =>
              setFeedData({ ...feedData, leagues: e.target.value })
            }
            value={feedData.leagues}
          >
            <option value={'all'}>{'All'}</option>
            {games
              .reduce((acc, el) => {
                if (!acc.includes(`${el.leagues} || ${el.country}`)) {
                  acc.push(`${el.leagues} || ${el.country}`)
                }
                return acc
              }, [])
              .sort()
              .map(ele => (
                <option value={ele}>{ele}</option>
              )) //summing element
            }
          </Select>
        </Box>
        <Box w="100%" h="10">
          <Select
            placeholder="Club"
            onChange={e => setFeedData({ ...feedData, club: e.target.value })}
            value={feedData.club}
          >
            <option value={'all'}>{'All'}</option>
            {games
              .reduce((acc, el) => {
                if (!acc.includes(el.homeTeam)) {
                  acc.push(el.homeTeam)
                }
                if (!acc.includes(el.awayTeam)) {
                  acc.push(el.awayTeam)
                }
                return acc
              }, [])
              .sort()
              .map(ele => (
                <option value={ele}>{ele}</option>
              )) //summing element
            }
          </Select>
        </Box>
        <Box w="100%" h="10">
          <Input
            type={'date'}
            onChange={e =>
              setFeedData({ ...feedData, dateStart: e.target.value })
            }
            value={feedData.dateStart}
            placeholder="Start Date"
          />
        </Box>
        <Box w="100%" h="10">
          <Input
            type={'date'}
            onChange={e =>
              setFeedData({ ...feedData, dateEnd: e.target.value })
            }
            value={feedData.dateEnd}
            placeholder="End Date"
          />
        </Box>
        <Box w="100%" h="10">
          <Input
            onChange={e =>
              setFeedData({ ...feedData, totalItems: e.target.value })
            }
            value={feedData.totalItems}
            placeholder="Number of Items or All for every item"
          />
        </Box>
        <Box w="100%" h="10">
          <Select
            placeholder="Sort or Random"
            onChange={e => setFeedData({ ...feedData, sort: e.target.value })}
            value={feedData.sort}
          >
            <option value={'highlight'}>{'highlight'}</option>
            <option value={'random'}>{'random'}</option>
            <option value={'data'}>{'date'}</option>
          </Select>
        </Box>
      </Grid>
      <Heading as="h3" color={'#000'} size="lg" margin={'4em 0 1em 0'}>
        Banner Preview
      </Heading>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <Box w="100%" h="10">
          <Textarea
            value={iframeContent}
            onChange={e => setIframeContent(e.target.value)}
            placeholder="Place you banner code here"
          />
        </Box>
        <Box w="100%">
          <Frame height={'800px'} width={'100%'}>
            <div
              dangerouslySetInnerHTML={{
                __html: iframeContent
              }}
            />
            {!iframeContent && (
              <pre
                className="javascript"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(bannerData, null, 2)
                }}
              />
            )}
          </Frame>
        </Box>
      </Grid>
    </div>
  )
}
