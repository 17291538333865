import React, { Component } from 'react'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import openSocket from 'socket.io-client'

import './App.css'
import customTheme from '../theme'
import { presistData, getPresistData } from '../util'
import Page from '../components/page'
import Login from '../login/Login'
import Register from '../login/Register'
import Reset from '../login/Reset'
import Users from '../users/Users'
import Dashboard from '../dashboard/Dashboard'
import Preview from '../preview/Preview'
import WatchAuth from '../auth/WatchAuth'

class App extends Component {
  constructor(props) {
    super(props)

    let thisState
    //lets load user data for reload
    if (typeof localStorage !== 'undefined' && localStorage.app) {
      thisState = JSON.parse(localStorage.app)
    }

    this.state = {
      ...thisState,
      pageClass: 'login',
      games: [],
      user: [],
      socket: null
    }

    // if state is login then
    if (this.state.loggedIn) {
      this.state.socket = this.userLoggedIn(this.state.loggedIn)
    }
    this.setStorageState = this.setStorageState.bind(this)
    this.setRemoteCall = this.setRemoteCall.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.state.loggedIn && nextState.loggedIn) {
      const socket = this.userLoggedIn(nextState.loggedIn)
      this.setState({ socket })
    }

    return true
  }

  setRemoteCall(callType, data, message) {
    this.state.socket.emit(callType, data)
    toast(`Requesting from the server ${message}`)
  }

  userLoggedIn(loggedIn) {
    const socket = openSocket('https://api.oddsplacardpt.com', {
      upgrade: false,
      query: `token=${loggedIn.token}`,
      extraHeaders: { Authorization: `Bearer ${loggedIn.token}` }
      // transports: ['websocket'],
    })

    socket.on('unauthorized', (error, callback) => {
      if (
        error.data.type == 'UnauthorizedError' ||
        error.data.code == 'invalid_token'
      ) {
        // redirect user to login page perhaps or execute callback:
        console.log('User token has expired')
        return (window.location = '/')
      }
    })

    ///get initial data
    socket.emit('getInitData')
    toast('Fetching initial Data')

    socket.on('games', received => {
      this.setState({ games: received || [] })
      toast(`Got ${received.length} games`)
    })
    socket.on('users', received => {
      this.setState({ users: received || [] })
      toast(`Got ${received.length} users`)
    })
    return socket
  }

  checkAuthRoute(redirectIfLoggedin) {
    if (
      !this.state.loggedIn &&
      !getPresistData('app', 'loggedIn') &&
      window.location.pathname !== '/'
    ) {
      return (window.location = '/')
    } else if (
      redirectIfLoggedin &&
      this.state.loggedIn &&
      getPresistData('app', 'loggedIn')
    ) {
      return (window.location = '/dashboard')
    }
    return true
  }

  setStorageState(type, data) {
    if (this.state[type] !== data) {
      presistData('app', { ...this.state, [type]: data, socket: null })
      this.setState({ [type]: data })
    }
  }

  render() {
    const isAdmin = this.state.loggedIn && this.state.loggedIn.admin
    return (
      <ThemeProvider theme={customTheme}>
        <CSSReset />
        <Router>
          <WatchAuth setStorageState={this.setStorageState}>
            <Page
              setRemoteCall={this.setRemoteCall}
              className={this.state.pageClass}
              loggedIn={this.state.loggedIn}
              setStorageState={this.setStorageState}
              user={this.state.loggedIn}
              socket={this.state.socket}
            >
              <Switch>
                <Route
                  exact
                  path="/dashboard"
                  component={() => {
                    return (
                      this.checkAuthRoute() && (
                        <Dashboard
                          data={this.state.games}
                          setRemoteCall={this.setRemoteCall}
                          setStorageState={this.setStorageState}
                          socket={this.state.socket}
                        />
                      )
                    )
                  }}
                />
                {this.state.loggedIn && this.state.loggedIn.role !== '' && (
                  <Route
                    exact
                    path="/preview"
                    component={() =>
                      this.checkAuthRoute() && (
                        <Preview
                          data={this.state.games}
                          user={this.state.loggedIn}
                          users={this.state.users}
                          setStorageState={this.setStorageState}
                        />
                      )
                    }
                  />
                )}

                <Route
                  exact
                  path="/reset/:token"
                  component={() => (
                    <Reset setStorageState={this.setStorageState} />
                  )}
                />
                {isAdmin && (
                  <Route
                    exact
                    path="/users"
                    component={() =>
                      this.checkAuthRoute() && (
                        <Users
                          setRemoteCall={this.setRemoteCall}
                          data={this.state.users}
                          setStorageState={this.setStorageState}
                        />
                      )
                    }
                  />
                )}
                <Route
                  exact
                  path="/"
                  component={() =>
                    this.checkAuthRoute(true) && (
                      <Login setStorageState={this.setStorageState} />
                    )
                  }
                />
              </Switch>
            </Page>
          </WatchAuth>
        </Router>
        <ToastContainer hideProgressBar={true} />
      </ThemeProvider>
    )
  }
}

export default App
