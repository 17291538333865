import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Heading, Flex, Text, Button } from '@chakra-ui/core'

const MenuItems = ({ children }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    {children}
  </Text>
)

const Header = props => {
  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)

  const logout = () => {
    props.setStorageState('loggedIn', null)
  }

  console.log(props.user)

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="#000"
      color="white"
      {...props}
    >
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg" letterSpacing={'-.1rem'}>
          <img src={'sas-logo.png'} />
        </Heading>
      </Flex>

      <Box display={{ sm: 'block', md: 'none' }} onClick={handleToggle}>
        <svg
          fill="white"
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Box
        display={{ sm: show ? 'block' : 'none', md: 'flex' }}
        width={{ sm: 'full', md: 'auto' }}
        alignItems="center"
        flexGrow={1}
      >
        <MenuItems>
          <Link to={'dashboard'} onClick={() => props.socket.emit('getGames')}>
            List
          </Link>
        </MenuItems>
        <MenuItems>
          <Link to={'preview'}>Preview</Link>
        </MenuItems>
        {(props.user.admin == 'true' || props.user.admin == true) && (
          <MenuItems>
            <Link to={'users'}>Users</Link>
          </MenuItems>
        )}
      </Box>

      <Box
        display={{ sm: show ? 'block' : 'none', md: 'flex' }}
        width={{ sm: 'full', md: 'auto' }}
        // mt={{ base: 4, md: 0 }}
      >
        {/* {(props.user.admin == 'true' || props.user.admin == true) && (
          <MenuItems>
            <Button
              color={'#000'}
              onClick={() =>
                props.setRemoteCall('fetchGamesData', {}, ' new games')
              }
              border="1px"
            >
              Force Update
            </Button>
          </MenuItems>
        )} */}
        <MenuItems>
          <Button bg="transparent" onClick={() => logout()} border="1px">
            Logout
          </Button>
        </MenuItems>
      </Box>
    </Flex>
  )
}

export default Header
